import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//icons
import { ReactComponent as CircleCloseIcon } from "../../../../../assets/icons/icon-close-circle.svg";
//redux
import { useAppSelector } from "../../../../../redux/hooks";
import { subcategorySchema } from "../../../../../validations/subCategoryValidation";
import { getProgramsList, setProgramsList, updateSubCategory } from "../../../../../redux/pages/categorySlice";

interface EditInfo {
  showModal: (value: boolean) => void;
  subprogramInfo: any;
}

const EditsubCategoryModal: React.FC<EditInfo> = (props) => {
  const {
    showModal = () => { },
    subprogramInfo,
  } = props;

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      program_id: subprogramInfo?.program_id,
      name: subprogramInfo.name,
      price: subprogramInfo.price,
      description: subprogramInfo.description
    },
    resolver: yupResolver(subcategorySchema),
  });

  const [programList, setProgramList] = useState<any>([])

  const dispatch = useDispatch<any>();
  const programInfo = useAppSelector((state) => state.programs.programList);

  const onEditFormSubmit = async (data: any) => {
    if (data) {
      const submitData = { ...data, status: "active", id: subprogramInfo.id, program_id: subprogramInfo?.program_id };
      dispatch(updateSubCategory(submitData, showModal));
    }
  };

  useEffect(() => {
    dispatch(getProgramsList({}));
  }, []);

  useEffect(() => {
    if (programInfo) {
      setProgramList(programInfo);
      dispatch(setProgramsList(""));
    }
  }, [programInfo])

  return (
    <div
      className="as-modal edit-category-modal"
      id="staticBackdrop"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-3">
              <h4 className="modal-title">Edit Subcategory</h4>
              <button
                className="close-button close-modal-btn"
                onClick={() => showModal(false)}
              >
                <CircleCloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onEditFormSubmit)}>
                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Subcategory Name</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Subcategory name"
                    {...register("name")}
                    name="name"
                  />
                  <p className="validation-text">
                    {errors.name?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Price</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Price"
                    {...register("price")}
                    name="price"
                  />
                  <p className="validation-text">
                    {errors.price?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-4">
                  <label className="form-label">Description</label>
                  <textarea
                    {...register("description")}
                    name="description"
                    rows={4}
                    className="form-group-textarea"
                  ></textarea>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger close-modal-btn"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditsubCategoryModal;
