import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./header.scss";

// icons
import { ReactComponent as NotificationDotsIcon } from "../../../assets/icons/icon-notification-dots.svg";
import iconBell from "../../../assets/icons/icon-bell.svg";
import avatar from "../../../assets/images/avatar.png";

import { getUserPermissions } from "../../../redux/pages/loginSlice";

const Header = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getUserPermissions());
  }, [])

  return (
    <div className="as-header-wrapper">
      <div className="d-flex align-items-center justify-content-between h-100">
        <div className="as-user-det">
          {/* <h4 className="as-user-name">John Doe</h4>
          <p className="as-user-role">Admin</p> */}
        </div>
        <div className="as-profile-sec">
          <div className="dropdown as-notification-dropdown me-4">
            <div
              className="dropdown-toggle"
              // type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="as-notification-dropdown-icon">
                <img src={iconBell} alt="bell-icon" />
                <div className="icon-badge"></div>
              </div>
            </div>

            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item">
                  <div className="notification-item">
                    <div>
                      <span className="icon">
                        <NotificationDotsIcon />
                      </span>
                    </div>
                    <div className="notification-body">
                      <h6 className="title">System Notification</h6>
                      <p className="description">
                        Your password has been changed successfully
                      </p>
                      <div className="date">5 June 2023</div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item">
                  <div className="notification-item">
                    <div>
                      <span className="icon">
                        <NotificationDotsIcon />
                      </span>
                    </div>
                    <div className="notification-body">
                      <h6 className="title">System Notification</h6>
                      <p className="description">
                        Your password has been changed successfully sjdfld
                        sflkdsjf klsjflkdsjslfsdljfsl sfjdlk slkjfdslk
                        fslkjlskjflksjf slkjlkjslkjflkdsj lksjflksjf
                        lksjlskjfslk flskjlskjflskjf slklkj flkjsflsjl
                      </p>
                      <div className="date">5 June 2023</div>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item">
                  <div className="notification-item">
                    <div>
                      <span className="icon">
                        <NotificationDotsIcon />
                      </span>
                    </div>
                    <div className="notification-body">
                      <h6 className="title">System Notification</h6>
                      <p className="description">
                        Your password has been changed successfully sjdfld
                        sflkdsjf klsjflkdsjslfsdljfsl sfjdlk slkjfdslk
                        fslkjlskjflksjf slkjlkjslkjflkdsj lksjflksjf
                        lksjlskjfslk flskjlskjflskjf slklkj flkjsflsjl
                      </p>
                      <div className="date">5 June 2023</div>
                    </div>
                  </div>
                </a>
              </li>
              <li className="seperator-line">
                <hr className="line" />
              </li>
              <li className="d-flex align-items-center justify-content-center mt-0">
                <button
                  onClick={() => navigate("/notifications")}
                  className="btn btn-link fw-bold inline-btn"
                >
                  View all Notifications
                </button>
              </li>
            </ul>
          </div>
          <div className="dropdown as-profile-dropdown me-4">
            <div
              className="dropdown-toggle"
              // type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="as-profile-icon">
                <img src={avatar} alt="user-profile-image" />
              </div>
            </div>

            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item">Profile</a>
              </li>
              <li>
                <a className="dropdown-item">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
