import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import "./CourseDetails.scss";

import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { useDispatch } from "react-redux";
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/icons/icon-bookmark.svg";
import { ReactComponent as NoteIcon } from "../../../assets/icons/icon-note.svg";
import { ReactComponent as TaskSquareIcon } from "../../../assets/icons/icon-task-square.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/icon-upload.svg";
import { ReactComponent as CircleAddIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as CloseCircleIcon } from "../../../assets/icons/icon-close-circle.svg";
import { ReactComponent as PictureIcon } from "../../../assets/icons/icon-picture.svg";
import { ReactComponent as MenuStarIcon } from "../../../assets/icons/icon-menu-star.svg";
import { ReactComponent as PlayCircleIcon } from "../../../assets/icons/icon-play-circle.svg";

import clipBoardImage from "../../../assets/images/clipboard-img.svg";

import DeleteModal from "../../../common/components/Modal/DeleteModal/DeleteModal";
import CustomStepper from "../../../common/components/CustomStepper/CustomStepper";
import VideoCard from "./components/VideoCard";
import { useAppSelector } from "../../../redux/hooks";
import {
  deleteCourse,
  getCourseDetails,
  setNewSubjectInfo,
  setSubjectDetails,
} from "../../../redux/pages/courseSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../../../config";

const CourseDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const studentData = useAppSelector((state) => state.course.courseList);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isNavigate, setIsNavigate] = useState<boolean>(false);
  const [chapterData, setChapterdata] = useState<any>([]);
  const [openChapter, setOpenChapter] = useState<any>();
  const [showTest, setShowTest] = useState<boolean>(false);
  const [subject_id, setSubject_id] = useState<any>([]);
  const [studentList, setStudentList] = useState<any>([]);

  const dispatch = useDispatch<any>();
  const subjectDetails = useAppSelector((state) => state.course.subjectDetails);

  useEffect(() => {
    if (subjectDetails) {
      setSubject_id(subjectDetails?.id);
    }
  }, [subjectDetails]);

  useEffect(() => {
    if (studentData) {
      setStudentList(studentData);
    }
  }, [studentData]);

  useEffect(() => {
    if (location && location?.state && location?.state?.courseData) {
      dispatch(getCourseDetails(location?.state?.courseData?.id));
    }
  }, [location]);

  useEffect(() => {
    if (isNavigate) {
      navigate("/courses");
      setIsNavigate(false);
    }
  }, [isNavigate]);

  const handleEditClick = () => {
    if (subjectDetails) {
      dispatch(setNewSubjectInfo(subjectDetails));
      dispatch(setSubjectDetails(""));
      navigate("/courses/manage_course");
    }
  };

  const handleDelete = () => {
    if (subjectDetails && subjectDetails?.id) {
      setShowDeleteModal(true);
    }
  };

  const onConfirmDelete = () => {
    if (subjectDetails && subjectDetails?.id) {
      const data = { id: subjectDetails?.id };
      dispatch(deleteCourse(data, setShowDeleteModal, null, setIsNavigate));
    }
  };

  const onCancelDeleteAction = () => {
    setShowDeleteModal(false);
  };

  const formatDate = (dateString: string) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = format(date, "dd MMMM yyyy");
      return formattedDate;
    }
  };

  const testHandler = (id: any) => {
    setShowTest(true);
    // setShowVideoPlayer(false);
    // dispatch(questionList(id));
    setShowTest(!showTest);
  };

  useEffect(() => {
    if (subjectDetails) {
      setChapterdata(subjectDetails?.chapters);
    }
  }, [subjectDetails]);

  return (
    <>
      <div className="course-details-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-3 mx-4">
          <button className="back-button" onClick={() => navigate(-1)}>
            <ArrowLeftIcon />
          </button>
          <div>
            <h2 className="title">Manage Course</h2>
            <p className="desc mb-2">Here the details of each course</p>
          </div>
          <div className="d-flex gap-3">
            <a
              className="btn btn-sm btn-outline-primary"
              onClick={handleEditClick}
            >
              <span className="me-1">
                <EditIcon />
              </span>
              Edit Course
            </a>
            <a className="btn btn-sm btn-outline-danger" onClick={handleDelete}>
              <span className="me-1">
                <TrashIcon />
              </span>
              Delete Course
            </a>
          </div>
        </div>
        <div className="">
          <div className="details-wrap mx-4 pb-4">
            <div className="row">
              <div className="col-lg-4 pb-4 pb-lg-0">
                <div className="card course-det-card">
                  <div className="img-wrap">
                    <img
                      src={subjectDetails?.thumb_image}
                      alt="course details thumbnail"
                    />
                  </div>
                  <div className="course-card-body pt-2">
                    <h5 className="course-card-title">
                      {subjectDetails?.name}
                    </h5>
                    <p className="card-desc">
                      Created on {formatDate(subjectDetails?.created_at)}
                    </p>
                    <p className="card-desc">Duration : 2hr 25min</p>
                    <div className="d-flex align-items-center user-det-sec">
                      <div className="avatar-wrap">
                        <img
                          src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZGF0YSUyMHNjaWVuY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                          alt="user image"
                        />
                      </div>
                      <div className="avatar-body">
                        {/* <p className="created-by">Created by</p>
                        <h6 className="user-name">Mathew A</h6>
                        <p className="user-role">Asst. Professor</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card description-card">
                  <h6 className="card-subtitle">Description</h6>
                  <p className="description">{subjectDetails?.description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="section-wrap mx-4 pb-1">
            <div className="row">
              <div className="col-lg-8 pb-4 pb-lg-0">
                <div className="card scrollable-card">
                  <h6 className="card-subtitle">Chapters</h6>
                  <div className="card-scroll-area">
                    <div
                      className="accordion accordion-flush chapter-test-accordion"
                      id="accordionFlushExample"
                    >
                      {chapterData?.map((chapter: any) => (
                        <div className="accordion-item mb-2" key={chapter.id}>
                          <h2
                            className="accordion-header"
                            id={`flush-headingOne${chapter.id}`}
                          >
                            
                            <button
                              className={`accordion-button ${
                                openChapter === chapter.id ? "" : "collapsed"
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#flush-collapseOne${chapter.id}`}
                              aria-expanded={
                                openChapter === chapter.id ? "true" : "false"
                              }
                              aria-controls={`flush-collapseOne${chapter.id}`}
                            >
                              {chapter?.name}
                            </button>

                          </h2>
                          <div
                            id={`flush-collapseOne${chapter.id}`}
                            className={`accordion-collapse collapse${
                              openChapter === chapter.id ? " show" : ""
                            }`}
                            aria-labelledby={`flush-headingOne${chapter.id}`}
                            data-bs-parent="#accordionFlushExample"
                          >
                            
                              <div className="accordion-body">
                                <ul className="test-chapter-list">
                                  {chapter?.tutorial?.map((tutorial: any) => (
                                    <React.Fragment key={tutorial?.id}>
                                      <li>
                                        <div className="tutorial-card">
                                          <div className="title-sec">
                                            <div className="thumbnail-wrap">
                                              <div className="dark-overlay"></div>
                                              <img src={tutorial?.thumb_image ? tutorial?.thumb_image : 'https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bWFufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60" alt="video thumbnail'} />
                                              <a href={`${baseURL}/${tutorial.video_link}`} className="play-icon" target="_blank">
                                                <PlayCircleIcon />
                                              </a>
                                            </div>
                                            <h6 className="title">
                                              {tutorial?.name}
                                            </h6>
                                          </div>
                                          <p className="duration">{tutorial?.duration}</p>
                                        </div>
                                      </li>

                                      <li>
                                        <div className="accordion-item accordion-inner">
                                          <h2
                                            className="accordion-header"
                                            id="flush-headingOne"
                                          >
                                            {tutorial?.test && (
                                              <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                onClick={() =>
                                                  testHandler(chapter?.test?.id)
                                                }
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#tutorial-test${tutorial?.test?.id}`}
                                                aria-expanded="false"
                                                aria-controls={`#tutorial-test${tutorial?.test?.id}`}
                                              >
                                                <div
                                                  className="d-flex w-100 justify-content-between align-items-center"
                                                  key={tutorial?.test?.id}
                                                >
                                                  <div className="d-flex inner-title-sec">
                                                    <div className="task-icon">
                                                      <TaskSquareIcon />
                                                    </div>
                                                    <h6 className="title">
                                                      {" "}
                                                      {tutorial?.test?.name}{" "}
                                                    </h6>
                                                  </div>
                                                  <div>
                                                    <p className="ques-count">
                                                      {tutorial?.test?.questions?.length} Que
                                                    </p>
                                                  </div>
                                                </div>
                                              </button>
                                            )}
                                          </h2>
                                          <div
                                            id={`tutorial-test${tutorial?.test?.id}`}
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne"
                                          >
                                            <div className="accordion-body">
                                              <ol className="questions-list ms-4">
                                                <li className="question">
                                                  Question 1
                                                </li>
                                                <li className="question">
                                                  Question 2
                                                </li>
                                                <li className="question">
                                                  Question 3
                                                </li>
                                                <li className="question">
                                                  Question 4
                                                </li>
                                              </ol>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </React.Fragment>
                                  ))}
                                  
                                  {chapter?.test && (
                                    <li>
                                      <div className="accordion-item accordion-inner">
                                        <h2
                                          className="accordion-header"
                                          id="flush-headingOne"
                                        >
                                          <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#chapter-test${chapter?.test?.id}`}
                                            aria-expanded="false"
                                            aria-controls={`#chapter-test${chapter?.test?.id}`}
                                          >
                                            <div className="d-flex w-100 justify-content-between align-items-center">
                                              <div className="d-flex inner-title-sec">
                                                <div className="task-icon">
                                                  <TaskSquareIcon />
                                                </div>
                                                <h6 className="title">
                                                  {" "}
                                                  {chapter?.test?.name}{" "}
                                                </h6>
                                              </div>
                                              <div>
                                                <p className="ques-count">
                                                  {chapter?.test?.questions?.length} Que
                                                </p>
                                              </div>
                                            </div>
                                          </button>
                                        </h2>
                                        <div
                                          id={`chapter-test${chapter?.test?.id}`}
                                          className="accordion-collapse collapse"
                                          aria-labelledby="flush-headingOne"
                                        >
                                          <div className="accordion-body">
                                            <ol className="questions-list ms-4">
                                              {chapter?.test?.questions?.map((question: any) => (
                                                <li className="question" key={question?.id}>{question?.question}</li>
                                              ))}
                                            </ol>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card scrollable-card">
                  <h6 className="card-subtitle ">Enrolled Students</h6>
                  <div className="card-scroll-area">
                    {studentList.map((course: any) =>
                      subject_id === course.id ? (
                        <ul className="student-list">
                          {course.students.map((student: any) => (
                            <li key={student?.id}>
                              <div className="students-card">
                                <div className="d-flex align-items-center">
                                  <div className="student-avatar-wrap">
                                    <img
                                      src={student?.profile_image}
                                      alt="student avatar"
                                    />
                                  </div>
                                  <h6 className="student-name">
                                    {student?.name}
                                  </h6>
                                </div>

                                <div className="dropdown more-dropdown">
                                  <button
                                    className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <DotsVerticalIcon />
                                  </button>

                                  <ul className="dropdown-menu">
                                    <li>
                                      <button
                                        // onClick={handleEdit}
                                        className="dropdown-item"
                                      >
                                        <span className="me-2">
                                          <EditIcon />
                                        </span>
                                        Edit
                                      </button>
                                    </li>

                                    {/* <li>
                                      <a className="dropdown-item">
                                        <span className="me-2">
                                          <MenuStarIcon />
                                        </span>
                                        Add Offer
                                      </a>
                                    </li> */}

                                    <li>
                                      <button
                                        className="dropdown-item text-danger"
                                        // data-bs-toggle="modal"
                                        // data-bs-target="#staticBackdrop"
                                        // onClick={handleDelete}
                                      >
                                        <span className="me-2">
                                          <TrashIcon />
                                        </span>
                                        Delete
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onConfirmDelete}
          onCancel={onCancelDeleteAction}
          message={`The course ${subjectDetails?.name} will be permanently deleted.`}
        />
      )}
    </>
  );
};

export default CourseDetails;
