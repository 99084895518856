import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";

import "./course.scss";
import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as GridPlusIcon } from "../../../assets/icons/icon-grid-plus.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/icon-category.svg";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";

import CourseCard from "./components/CourseCard";
import { getCourseList, setCourseList } from "../../../redux/pages/courseSlice";
import Search from "../../../common/components/Search/Search";
import AdvancedSort from "../../../common/components/AdvancedSort/AdvancedSort";
import { showUI } from "../../../common/utility/permissionManager/permissionUtility";

const Course = () => {

  //serach
  const [searchTerm, setSearchTerm] = useState<string>("");
  //sorting
  const [sortConfig, setSortConfig] = useState<any>({ sortOrder: "", sortBy: "", sort: "" });
  const [courseListData, setCourseListData] = useState<any>([]);
  
  //redux
  const dispatch = useDispatch<any>();
  const courseList = useAppSelector((state) => state.course.courseList);
  const permissions = useAppSelector((state) => state.login.userPermissions);

  useEffect(() => {
    dispatch(getCourseList({ search: "", sort: sortConfig?.sort }));
  }, []);

  useEffect(() => {
    if (courseList !== "") {
      setCourseListData(courseList);
      dispatch(setCourseList(""));
    }
  }, [courseList])

  const onSearch = useCallback((keyword: string) => {
    if (keyword) {
      dispatch(getCourseList({ search: keyword, sort: sortConfig?.sort }));
    }
  }, [sortConfig]);

  const onSort = useCallback((config: any) => {
    if (config) {
      setSortConfig(config);
      dispatch(getCourseList({ search: searchTerm, sort: config?.sort }));
    }
  }, [searchTerm]);

  return (
    <>
      <div className="course-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
          <div>
            <h2 className="title">Courses</h2>
            <p className="desc mb-2">Here you can manage all the courses</p>
          </div>
          <div className="d-flex al gap-3">
          {showUI("Category List", permissions) && <Link
              to="/courses/manage_category"
              className="btn btn-outline-primary"
            >
              <span className="me-1">
                <GridPlusIcon />
              </span>
              Manage Category
            </Link> }
            {showUI("Subcategory List", permissions) && <Link
              to="/courses/manage_subCategory"
              className="btn btn-outline-primary"
            >
              <span className="me-1">
                <GridPlusIcon />
              </span>
              Manage Subcategory
            </Link> }
            {showUI("Course Create", permissions) &&<Link to="/courses/manage_course" className="btn btn-primary">
              <span className="me-1">
                <AddCirclePlusIcon />
              </span>
              Create New Course
            </Link>}
          </div>
        </div>
        {/* control section */}
        <div className="control-section-wrapper mx-4">
          <div>
            <span className="control-sec-title mb-3">All Courses</span>
          </div>
          <div className="d-flex justify-content-between flex-wrap gap-3  control-wrapper py-3">
            <Search keyword={searchTerm} setKeyword={setSearchTerm} onSearch={onSearch} placeholder="Search Course" />
            <AdvancedSort sortOrder={sortConfig?.sortOrder} sortBy={sortConfig?.sortBy} onSort={onSort} />
          </div>
        </div>
        {/* card section */}
        <div className="ps-4 pe-3">
          <div className="row g-3">
            {courseListData?.map((item: any, index: any) => (
                <div key={index} className="col-12 col-lg-6 col-xl-4 col-xxl-3">
                  <CourseCard course={item} />
                </div>
              ))}
          </div>
        </div>
        {/* pagination section */}
        {/* <div className="d-flex align-items-center justify-content-center pt-5 pb-3">
          <nav aria-label="...">
            <ul className="pagination mb-0">
              <li className="page-item disabled me-4">
                <a className="page-link">
                  <span className="me-2">
                    <PaginationLeftArrowIcon />
                  </span>
                  Prev
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  1
                </a>
              </li>
              <li className="page-item active" aria-current="page">
                <a className="page-link">2</a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  3
                </a>
              </li>
              <li className="page-item disabled">
                <a className="page-link">
                  ..........
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link">
                  7
                </a>
              </li>
              <li className="page-item ms-4">
                <a className="page-link">
                  Next
                  <span className="ms-2">
                    <PaginationRightArrowIcon />
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
    </>
  );
};

export default Course;
