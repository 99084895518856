import React, { useEffect, useRef, useState } from "react";
import "./banner.scss";

// icons
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as UploadIcon } from "../../../assets/icons/icon-upload.svg";
import { ReactComponent as MonitorIcon } from "../../../assets/icons/icon-monitor.svg";
import { ReactComponent as MobileIcon } from "../../../assets/icons/icon-mobile.svg";

// images
import checkSquareImage from "../../../assets/icons/check-square-solid.png";
import bannerRightImage from "../../../assets/images/banner-illustration.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import {  createBannerSchema } from "../../../validations/bannerValidation";
import { useForm } from "react-hook-form";
import { createBanner, getBannerList } from "../../../redux/pages/bannerSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { getCourseList } from "../../../redux/pages/courseSlice";


const Banner = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(createBannerSchema),
  });

  const dispatch = useDispatch<any>();

  const bannerRef = useRef<any>(null)

  const [file, setFile] = useState<any>();
  const [selectedBanner, setSelectedBanner] = useState<any>(bannerRightImage);
  

  const courseList = useAppSelector((state) => state.course.courseList);
  const bannerList = useAppSelector((state) => state.banner.bannerList);

  const onHandleFileChange = (e: any) => {
    const fileItem = e?.target?.files[0];
    if (fileItem) {
      var fileName = fileItem?.name,
        idxDot = fileName.lastIndexOf(".") + 1,
        extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        setValue("image", fileItem);
        setFile(URL.createObjectURL(e.target.files[0]));
      } else {
        // setFileErrorMessage("Only images of type jpg, jpeg, png are allowed!");
      }
    }
  }


  useEffect(()=>{
   dispatch(getCourseList({ search: "", sort: "" }));
   dispatch(getBannerList());
  },[])

  const onBannerFormSubmit = (data:any) =>{
    if(data) {
      let formData = new FormData();
      formData.append("type", data?.type);
      formData.append("category", data?.category);
      formData.append("related_id", data?.related_id);
      if(file) {
        formData.append("image", data.image);
      };
      dispatch(createBanner(formData));
    }
  }

  
  return (
    <>
      <div className="banner-wrapper">
      <form onSubmit={handleSubmit(onBannerFormSubmit)} action="" className="h-100">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-1 mx-4">
          {/* <button className="back-button">
            <ArrowLeftIcon />
          </button> */}
          <div>
            <h2 className="title">Banner</h2>
            <p className="desc mb-2">
              Here you can add new users, manage them etc.
            </p>
          </div>
        </div>
        <div className="">
          <div className="card banner-card mx-4">
            <div className="row">
              <div className="col-12 ">
                <div className="d-flex flex-column">
                  <div className="mb-3">
                    <h6 className="subtitle">Upload Banner</h6>
                    <p className="description">
                      Upload banner for web,tablet and mobile devices
                    </p>
                  </div>
                  <hr className="line" />
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center gap-4">
                      <div className="form-radio-btn mb-0">
                        <input
                          type="radio"
                          name="radioDefaultbtn"
                          id="radiobtn1"
                        />
                        <label htmlFor="radiobtn1">Default</label>
                      </div>
                      <div className="form-radio-btn mb-0">
                        <input
                          type="radio"
                          name="radioDefaultbtn"
                          id="radiobtn2"
                        />
                        <label htmlFor="radiobtn2">Custom</label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <button className="toggle-btn" type="button">
                        <input
                              type="radio"
                              id="radio2"
                              checked={watch("type") === "web"}
                              onClick={() => setValue("type", "web")}
                            />
                          <span
                          {...register("type")}
                          onClick={()=>setValue("type","web")}  className="icon">
                            <MonitorIcon />
                          </span>
                          Web
                      </button>
                      <button className="toggle-btn" type="button">
                        <input
                              type="radio"
                              id="radio2"
                              checked={watch("type") === "mobile"}
                              onClick={() => setValue("type", "mobile")}
                            />
                          <span
                          {...register("type")}
                          onClick={()=>setValue("type","mobile")} className="icon">
                            <MobileIcon />
                          </span>
                          Mobile <sup className="text-danger">*</sup>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8">
                {/* for default banner */}
                {/* <div className="default-banner-img">
                  <img
                    src={bannerRightImage}
                    alt=""
                    className="banner-illustration"
                  />
                </div> */}
                {/* banner image */}
                <div className="banner-image-wrapper">
                  <img
                    src={selectedBanner}
                    className="banner-image"
                    alt="banner"
                  />
                </div>
              </div>
              <div className="col-4">
                  <div className="d-flex flex-column justify-content-between h-100">
                    <div className="">
                      <div className="form-group mb-3">
                      <p className="validation-text">{errors.type?.message}</p>
                        <label htmlFor="#category" className="form-group-label">
                          Category
                        </label>

                        <select
                          id="category"
                          {...register("category")}
                          className="form-select form-group-dropdown"
                        >
                          <option value="" >
                           Select a category
                          </option>
                            <option  value="offer" >
                           offer
                          </option>
                          <option  value="course" >
                           course
                          </option>
                          {/* <option className="form-group-option active selected">
                            category 1
                          </option>
                          <option className="form-group-option">
                            category 2
                          </option>
                          <option className="form-group-option">
                            category 3
                          </option> */}
                        </select>
                        <p className="validation-text">{errors.category?.message}</p>
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="#courses" className="form-group-label">
                          Courses
                        </label>
                        <select
                          id="course"
                          {...register("related_id")}
                          className="form-select form-group-dropdown"
                        >
                          <option value="" >
                           Select a course
                          </option>
                          {courseList &&
                          courseList?.map((data:any,index:any)=>{
                            return <>
                            <option key={index} value={data.id} >
                            {data.name}
                          </option>
                            </>
                          })
                        }
                          {/* <option className="form-group-option active selected">
                            courses 1
                          </option>
                          <option className="form-group-option">
                            courses 2
                          </option>
                          <option className="form-group-option">
                            courses 3
                          </option> */}
                        </select>
                        <p className="validation-text">{errors.related_id?.message}</p>
                      </div>

                      <p className="warning-text mb-3">
                        Upload Slides for mobile screens for apply changes
                      </p>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary w-100">
                        Apply
                      </button>
                    </div>
                    <p style={{color:"#d43d35"}} className="validation-text">{errors.image?.message}</p>
                  </div>
              </div>
              <div className="col-12">
                <div className="d-flex flex-wrap gap-3 mt-3">
                  {/* default banner preview */}
                  <div className={`default-banner-img-thumbnail ${selectedBanner === bannerRightImage ? 'selected' : ''}`} onClick={() => setSelectedBanner(bannerRightImage)}>
                    <img
                      src={bannerRightImage}
                      alt=""
                      className="banner-illustration"
                    />
                  </div>
                  {bannerList?.map((banner: any, index: any) => (
                    <div key={index} className={`banner-image-wrapper-thumbnail ${selectedBanner === banner.image ? 'selected' : ''}`} onClick={() => setSelectedBanner(banner.image)}>
                        <img
                          src={banner.image}
                          className="banner-image-thumbnail"
                          alt=""
                        />
                    </div>
                  ))}
                  
                  <input accept="image/png, image/jpeg" type="file" hidden ref={bannerRef} onChange={onHandleFileChange}/>
                  <button onClick={()=>bannerRef.current?.click()} type="button" className="upload-btn">
                    <span className="icon">
                      <UploadIcon />
                    </span>
                    <p className="text">Upload Banner</p>
                  </button>
                </div>
              </div>
              {/* <div className="col-12">
                <button className="btn btn-primary">Apply</button>
              </div> */}
            </div>
          </div>
        </div>
        </form>

      </div>
      
    </>
  );
};

export default Banner;
