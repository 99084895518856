import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface faqInterface {
    isLoading: boolean;
    errors: any;
    careerList: any;
}

const initialState: faqInterface = {
    isLoading: false,
    errors: null,
    careerList: "",
}

const careerSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setCareerList: (state, { payload }: PayloadAction<any>) => {
            state.careerList = payload;
        },
    }
})

export const careerStore = (body: any, showModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/career/store";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setLoading(false));
                dispatch(getCareerList());
                showModal(false);
                showNotification({
                    message: "New career added successfully",
                    theme: "light",
                    type: "success"
                })
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};


export const getCareerList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/career/index";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setCareerList(response.data?.career));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};

export const updateCareerDetails = (body: any, showModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/career/update";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setLoading(false));
                showModal(false);
                dispatch(getCareerList());
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};

export const deleteCareer = (body: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/career/delete";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setLoading(false));
                dispatch(getCareerList());
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};


export const {
    setLoading,
    setErrors,
    setCareerList,
} = careerSlice.actions

export default careerSlice.reducer;
