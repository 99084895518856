import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//validations
import { createCourseSchema } from "../../../../../../../../validations/courseValidations";
//redux
import { useAppSelector } from "../../../../../../../../redux/hooks";
import { createCourse, updateCourse } from "../../../../../../../../redux/pages/courseSlice";
import { getAllProgramsList, getAllSubProgramsList, setAllProgramsList, setSubProgramsList } from "../../../../../../../../redux/pages/categorySlice";
import axios from "axios";
import { baseURL } from "../../../../../../../../config";

interface subjectDetails {
   setSelectedTab: (data: any) => void;
};

const Subject = forwardRef<any, subjectDetails>((props, ref) => {
   const {
      setSelectedTab = () => { },
   } = props;


   const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      clearErrors,
   } = useForm({
      resolver: yupResolver(createCourseSchema),
      defaultValues: {
         name: "",
         program_id: "",
         sub_program_id: "",
         description: "",
         price: 0,
         features: [],
         status: "inactive",
         short_description: "",
         video_link: "",
      }
   });


   const subjectFormRef = useRef<any>();

   const [programsList, setProgramsList] = useState<any>([]);
   const [subProgramList, setSubProgramList] = useState<any>([]);

   const [addFlag, setAddFlag] = useState<boolean>(true);
   const [program_id, setProgram_id] = useState<any>("");
   const [introVideo, setIntroVideo] = useState<any>("");

   //redux
   const dispatch = useDispatch<any>();
   const allProgramsList = useAppSelector((state) => state.programs.allProgramsList);
   const subProgramInfo = useAppSelector((state) => state.programs.subProgramsList);
   const newSubject = useAppSelector((state) => state.course.newSubject);


   useImperativeHandle(ref, () => ({
      onSaveTrigger: () => {
         subjectFormRef.current?.requestSubmit();
      }
   }));

   useEffect(() => {
      dispatch(getAllProgramsList());
   }, []);

   useEffect(() => {
      if (newSubject) {
         setAddFlag(false);
         setProgram_id(newSubject?.program_id);
      } else {
         setAddFlag(true);
      }
   }, [newSubject]);

   useEffect(() => {
      if (program_id !== "") {
         dispatch(getAllSubProgramsList(program_id));
      }
   }, [program_id])

   useEffect(() => {
      if (subProgramInfo && subProgramInfo?.length) {
         setSubProgramList(subProgramInfo);
         dispatch(setSubProgramsList(""));
      }
   }, [subProgramInfo])

   useEffect(() => {
      if (allProgramsList && allProgramsList?.length) {
         setProgramsList(allProgramsList);
         dispatch(setAllProgramsList(""));
      }
   }, [allProgramsList]);

   useEffect(() => {
      if (newSubject) {
         console.log(newSubject?.video_link, 'newSubject?.video_link');
         
         setValue("name", newSubject?.name);
         setValue("description", newSubject?.description);
         setValue("program_id", newSubject?.program_id);
         setValue("sub_program_id", newSubject?.sub_program_id)
         setValue("short_description", newSubject?.short_description);
         setValue("video_link", newSubject?.video_link);
         clearErrors(["name", "description", "program_id"]);
      }
   }, [newSubject]);

   const handleFileUpload = async(e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      const formData: any = new FormData();
      formData.append('video', file);
      const accessToken = sessionStorage.getItem("token");

      const response = await axios.post(`${baseURL}/api/admin/tutorial/video/store`, formData, {
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
         }
      });

      if (response.data.status && response.data.status === 'success') {
         setValue('video_link', response?.data?.data?.path);
      }
   };


   const onSubmit = (submitData: any) => {
      if (submitData) {
         if (addFlag) {
            dispatch(createCourse(submitData, setSelectedTab));
         } else {
            if (newSubject) {
               const data = { ...submitData, id: newSubject?.id, };
               dispatch(updateCourse(data, setSelectedTab));
            }
         }
      }
   };

   return (
      <form ref={subjectFormRef} onSubmit={handleSubmit(onSubmit)}>
         <div className="card-title-wrapper">
            <h3 className="card-title">Course Creation</h3>
            <div className="line"></div>
         </div>
         <div className="form-wrapper">
            <div className="form-group mb-4">
               <h5 className="form-group-label">Subject</h5>
               <h6 className="form-group-hint">
                  Name of the subject
               </h6>
               <input
                  {...register("name")}
                  type="text"
                  className={`form-group-control ${errors?.name ? "error-border" : ""}`}
                  name="name"
               />
               <p className="validation-text">{errors?.name?.message}</p>
            </div>

            <div className="form-group mb-4">
               <h5 className="form-group-label">Description</h5>
               <h6 className="form-group-hint">
                  Write description about the course
               </h6>

               <textarea
                  {...register("description")}
                  id="chapter_desc"
                  rows={5}
                  className={`form-group-textarea ${errors?.description ? "error-border" : ""}`}
                  placeholder="Description"
                  name="description"
               ></textarea>
               <p className="validation-text"> {errors?.description?.message} </p>
            </div>

            <div className="form-group mb-4">
               <h5 className="form-group-label">Short description</h5>
               <h6 className="form-group-hint">
                  Write short description about the course
               </h6>

               <textarea
                  {...register("short_description")}
                  id="chapter_short_desc"
                  rows={5}
                  className={`form-group-textarea ${errors?.short_description ? "error-border" : ""}`}
                  placeholder="Description"
                  name="short_description"
               ></textarea>
               <p className="validation-text"> {errors?.short_description?.message} </p>
            </div>

            <div className={`form-group mb-4 ${!programsList?.length ? "disabled" : ""}`}>
               <h5 className="form-group-label">Category</h5>
               <h6 className="form-group-hint"> Select a category from below </h6>
               <select
                  {...register("program_id")}
                  id="program_id"
                  className={`form-select form-group-dropdown ${errors?.program_id ? "error-border" : ""}`}
                  onChange={(e) => setProgram_id(e.target.value)} >
                  <option value="" disabled> Select Category </option>
                  {programsList?.map((data: any, key: number) => (
                     <option key={key} value={data.id} className={"form-group-option"} >{data.name}</option>
                  ))}
               </select>


               <p className="validation-text"> {errors?.program_id?.message?.toString()} </p>
            </div>

            <div className={`form-group mb-4 ${!subProgramList?.length ? "disabled" : ""}`}>
               <h5 className="form-group-label">Subcategory</h5>
               <h6 className="form-group-hint"> Select a Subcategory from below </h6>
               <select
                  {...register("sub_program_id")}
                  id="sub_program_id"
                  className={`form-select form-group-dropdown ${errors?.program_id ? "error-border" : ""}`}>
                  <option value="" disabled> Select Category </option>
                  {subProgramList?.map((data: any, key: number) => (
                     <option key={key} value={data.id} className={"form-group-option"} >{data.name}</option>
                  ))}
               </select>


               <p className="validation-text"> {errors?.program_id?.message?.toString()} </p>
            </div>

            <div className="form-group mb-4">
               <h5 className="form-group-label">Video Upload</h5>
               <h6 className="form-group-hint">Upload a video for the course</h6>
               <input
                  type="file"
                  accept="video/*"
                  onChange={handleFileUpload}
                  className={`form-group-control ${errors?.video_link ? "error-border" : ""}`}
               />
               <p className="validation-text">{errors?.video_link?.message}</p>
            </div>

         </div>
      </form>
   );
});

export default memo(Subject);