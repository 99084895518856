import * as yup from "yup";

export const subcategorySchema = yup.object().shape({
   name: yup.string().required("Subcategory name is required"),
   price: yup
      .number()
      .typeError("Invalid amount")
      .required("Category price is required")
      .min(0, "Minimum value should be 0")
      .positive()
      .integer(),
    program_id: yup.string().trim().required("Select a category"),
   description: yup.string().required("Description is required"),
});
