import React, { useEffect, useState } from "react";
import "./payments.scss";

// icons
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/pagination-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/pagination-arrow-right.svg";
import { ReactComponent as GreenArrowIcon } from "../../../assets/icons/icon-arrow-up-green.svg";
import { ReactComponent as RedArrowIcon } from "../../../assets/icons/icon-arrow-up-red.svg";
import { ReactComponent as ChevronDownIcon } from "../../../assets/icons/icon-chevron-down.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { ReactComponent as UsersIcon } from "../../../assets/icons/icon-users.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/icon-copy.svg";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { getPaymentList } from "../../../redux/pages/paymentSlice";

const offersList = [
  {
    id: 1,
    studentName: "John doe",
    course: "Programming in C",
    transactionId: "503847376438",
    method: "UPI",
    date: "22 Aug 23",
    status: "Success",
  },
  {
    id: 2,
    studentName: "John doe",
    course: "Programming in C",
    transactionId: "503847376438",
    method: "Card payment",
    date: "22 Aug 23",
    status: "Failed",
  },
  {
    id: 3,
    studentName: "John doe",
    course: "Programming in C",
    transactionId: "503847376438",
    method: "Net banking",
    date: "22 Aug 23",
    status: "Pending",
  },
  {
    id: 4,
    studentName: "John doe",
    course: "Programming in C",
    transactionId: "503847376438",
    method: "UPI",
    date: "22 Aug 23",
    status: "Success",
  },
  {
    id: 5,
    studentName: "John doe",
    course: "Programming in C",
    transactionId: "503847376438",
    method: "Card payment",
    date: "22 Aug 23",
    status: "Pending",
  },
  {
    id: 6,
    studentName: "John doe",
    course: "Programming in C",
    transactionId: "503847376438",
    method: "Net banking",
    date: "22 Aug 23",
    status: "Failed",
  },
];

const Payments = () => {

  const paymentInfo = useAppSelector((state) => state.payment?.paymentList);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getPaymentList());
  }, []);

  return (
    <>
      <div className="payments-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between align-items-center flex-wrap title-wrapper mb-1 mx-4">
          {/* <button className="back-button">
            <ArrowLeftIcon />
          </button> */}
          <div>
            <h2 className="title">Payments</h2>
            <p className="desc mb-2">Statistics of Assurance</p>
          </div>
        </div>

        <div className="">
          <div className="details-wrap mx-4">
            <div className="row g-4">
              <div className="col-lg-6 col-xl-4">
                <div className="card dashboard-card">
                  <div className="d-flex align-items-center justify-content-between card-head-sec">
                    <h4 className="title">Total Revenue</h4>
                  </div>
                  <hr className="line" />
                  <div className="count-wrap">
                    <h3 className="count">$230000.00</h3>
                    <div className="card-badge green-badge">
                      <span className="rate">+7.5</span>
                      <span className="icon">
                        <GreenArrowIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-5">
                <div className="card dashboard-card">
                  <div className="d-flex align-items-center justify-content-between card-head-sec">
                    <h4 className="title">Total Transactions</h4>
                    <div className="dropdown sort-dropdown">
                      <button
                        className="control-btn dropdown-toggle"
                        // type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="icon me-1">
                          <ChevronDownIcon />
                        </span>
                        This Month
                      </button>

                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item">This Month</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Last Month</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Last 3 Months</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Last 6 Months</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr className="line" />
                  <div className="count-wrap">
                    <h3 className="count">$230000</h3>
                    <div className="card-badge green-badge">
                      <span className="rate">+7.5</span>
                      <span className="icon">
                        <GreenArrowIcon />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="card dashboard-card">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="title">Registration Statistics</h4>
                    <div className="dropdown sort-dropdown">
                      <button
                        className="control-btn dropdown-toggle"
                        // type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="icon me-1">
                          <ChevronDownIcon />
                        </span>
                        This Month
                      </button>

                      <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                          <a className="dropdown-item">This Month</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Last Month</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Last 3 Months</a>
                        </li>
                        <li>
                          <a className="dropdown-item">Last 6 Months</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr className="line" />
                  <div className="table-wrapper offers-table">
                    <table className="table table-hover table-borderless table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">Student Name</th>
                          <th scope="col">Course</th>
                          <th scope="col">Transaction Id</th>
                          <th scope="col">Method</th>
                          <th scope="col">Date</th>
                          <th scope="col">Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {offersList.length > 0 ? (
                          offersList.map((item) => {
                            return (
                              <tr className="table-row">
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="avatar me-2">
                                      <img
                                        src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=800&q=60̉"
                                        alt=""
                                      />
                                    </div>
                                    <p className="text">{item.studentName}</p>
                                  </div>
                                </td>
                                <td>
                                  <p className="text">{item.course}</p>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <p className="text">
                                      #{item.transactionId}
                                    </p>
                                    <button className="icon-btn ms-1">
                                      <span>
                                        <CopyIcon />
                                      </span>
                                    </button>
                                  </div>
                                </td>
                                <td>
                                  <p className="text">{item.method}</p>
                                </td>
                                <td>
                                  <p className="text">{item.date}</p>
                                </td>
                                <td>
                                  {/* add active class for status active, and expired for expired status */}
                                  <p className="status success">
                                    {item.status}
                                  </p>
                                  {/* <p className="status pending">
                                    {item.status}
                                  </p> */}
                                  {/* <p className="status failed">{item.status}</p> */}
                                </td>
                                <td>
                                  <div className="dropdown more-dropdown">
                                    <button
                                      className="dropdown-toggle more-dropdown-btn dropdown-arrow-hide"
                                      // type="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <DotsVerticalIcon />
                                    </button>

                                    <ul className="dropdown-menu dropdown-menu-end">
                                      <li>
                                        <button className="dropdown-item">
                                          Mark it as a success
                                        </button>
                                      </li>
                                      <li>
                                        <button className="dropdown-item text-danger">
                                          Suspend transaction
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="empty-wrapper">
                            <td colSpan={5} className="mx-auto">
                              <div className="empty-container p-5">
                                {/* <img
                                  src={EmptyOffersImage}
                                  alt="empty offer illustration"
                                /> */}
                                <p className="description">
                                  No Transactions yet!!
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* pagination section */}
                  <div className="d-flex align-items-center justify-content-center pb-3">
                    <nav aria-label="...">
                      <ul className="pagination mb-0">
                        <li className="page-item disabled me-4">
                          <a className="page-link">
                            <span className="me-2">
                              <PaginationLeftArrowIcon />
                            </span>
                            Prev
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item active" aria-current="page">
                          <a className="page-link">2</a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item disabled">
                          <a className="page-link" href="#">
                            ..........
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            5
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            6
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            7
                          </a>
                        </li>
                        <li className="page-item ms-4">
                          <a className="page-link" href="#">
                            Next
                            <span className="ms-2">
                              <PaginationRightArrowIcon />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
