import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./CreateCategoryModal.scss";

import { ReactComponent as CircleCloseIcon } from "../../../../../assets/icons/icon-close-circle.svg";
import { useDispatch } from "react-redux";
import { createProgram } from "../../../../../redux/pages/categorySlice";
import { categorySchema } from "../../../../../validations/categoryValidation";

interface CreateInfo {
  showModal: (value: boolean) => void;
}

const CreateCategoryModal: React.FC<CreateInfo> = (props) => {
  const {
    showModal = () => {},
  } = props;

  const [file, setFile] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(categorySchema),
  });

  //redux
  const dispatch = useDispatch<any>();
  
  const onCategoryFormSubmit = (data: any) => {
    if(data) {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('price', data.price);
      formData.append('image', file);
      formData.append('description', data.description);

      dispatch(createProgram(formData, showModal));
    }
  };

  const fileChange = (e: any) => {
    const fileItem = e?.target?.files[0];
    if (fileItem) {
      var fileName = fileItem?.name,
      idxDot = fileName.lastIndexOf(".") + 1,
      extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png" || extFile === "webp") {
        setFile(fileItem);
      } else {
        alert('Only image of type jpg, png, jpeg, webp allowed')
        // setFileErrorMessage("Only images of type jpg, jpeg, png are allowed!");
      }
    }
  }


  return (
    <div
      className="as-modal create-category-modal"
      id="staticBackdrop"
      aria-hidden="true"
    >
      <div className="modal-dialog-wrapper">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header mb-3">
              <h4 className="modal-title">Create New Category</h4>
              <button
                className="close-button close-modal-btn"
                onClick={() => showModal(false)}
              >
                <CircleCloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onCategoryFormSubmit)}>
                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Category Name</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Category name"
                    {...register("name")}
                    name="name"
                  />
                  <p className="validation-text">{errors.name?.message}</p>
                </div>
                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Category Price</label>
                  <input
                    type="text"
                    className="form-group-control"
                    placeholder="Category Price"
                    {...register("price")}
                    name="price"
                  />
                  <p className="validation-text">
                    {errors.price?.message?.toString()}
                  </p>
                </div>
                <div className="category-form-control-wrap mb-3">
                  <label className="form-label">Image</label>
                  <input
                    type="file"
                    className="form-group-control"
                    name="image"
                    onChange={fileChange}
                  />
                </div>
                <div className="category-form-control-wrap mb-4">
                <label className="form-label">Category Description</label>
                  <textarea
                     {...register("description")}
                     name="description"
                     rows={4}
                     className="form-group-textarea"
                  ></textarea>
               </div>
                <div className="d-flex justify-content-between  mt-4">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-danger close-modal-btn"
                    onClick={() => showModal(false)}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-sm btn-primary">
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategoryModal;
