import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";


export interface bannerInterface {
    isLoading: boolean,
    errors: any
    bannerList: any,
}

const initialState:bannerInterface = {
    isLoading: false,
    errors: null,
    bannerList: [],
}



const bannerSlice = createSlice({
    name:"banner",
    initialState,
    reducers : {
        setErrors : (state, {payload}:PayloadAction<any>) =>{
            state.errors = payload;
        },
        setLoading : (state, {payload}:PayloadAction<any>) =>{
            state.errors = payload;
        },
        setBanner : (state, {payload}:PayloadAction<any>) =>{
            state.bannerList = payload;
        }
    }
})


export const createBanner = (data:any):AppThunk => async(dispatch) =>{
   try{
    let URL = "/banner/store"
    const response = await axiosInstance({data:{url:URL,method:"post",data:data},token:true})
    if(response?.data){
         if(response?.data && response.data === "success"){
            showNotification({
                message: "New Offers added successfully",
                theme: "light",
                type: "success"
            })
         }
    }
   }catch(error:any){
      dispatch(setLoading(false))
      dispatch(setErrors(error?.response))
   }
}

export const getBannerList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = '/banner/index';
        const response = await axiosInstance({ data: { url: URL, method: 'post', data: null }, token: true });
        if (response.data) {
            if (response.data.status && response.data.status === 'success') {
                dispatch(setBanner(response?.data?.banners));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response))
    }
};






export const {
    setErrors,
    setLoading,
    setBanner
}

 = bannerSlice.actions

 export default bannerSlice.reducer