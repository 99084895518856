import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./modal-styles.scss";

import { useDispatch } from "react-redux";
import { faqSchema } from "../../../../../validations/faqValidation";
import { updateFaqDetails } from "../../../../../redux/pages/faqSlice";

interface IFAQ {
    data: any
  setShowFAQModal: (value: boolean) => void;
}

const EditFAQModal: React.FC<IFAQ> = (props) => {
  const {
    data = "",
    setShowFAQModal = () => { },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(faqSchema),
  });

  const createForm = useRef<any>();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (data) {
      setValue("question", data?.question);
      setValue("answer", data?.answer);
    }
  }, [data]);


  const onSubmit = (submitData: any) => {
    if (submitData) {
      submitData.id = data?.id;
      dispatch(updateFaqDetails(submitData, setShowFAQModal));
    }
  }


  return (
    <div
      className="as-modal add-member-modal"
      id="add-member-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit FAQ</h4>
              <button
                className="close-modal-btn"
              >
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex gap-4">
                <div className="tab-section">
                  <form onSubmit={handleSubmit(onSubmit)} ref={createForm}>

                    <div className="form-wrapper">


                      <div className="form-group">
                        <h5 className="form-group-label">Question</h5>
                        <textarea
                          rows={2}
                          className={`form-group-control ${errors.question ? "error-border" : ""}`}
                          placeholder="Question"
                          {...register("question")}
                          name="question"
                        />
                        <p className="validation-text">{errors.question?.message}</p>
                      </div>

                      <div className="form-group">
                        <h5 className="form-group-label">Answer</h5>
                        <textarea
                          rows={4}
                          className={`form-group-control ${errors.answer ? "error-border" : ""}`}
                          placeholder="Answer"
                          {...register("answer")}
                          name="answer"
                        />
                        <p className="validation-text">{errors.answer?.message}</p>
                      </div>

                    </div>


                    <div className="d-flex btn-wrapper justify-content-between mt-3">
                      <button
                        onClick={() => setShowFAQModal(false)}
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary close-modal-btn"
                        onClick={() => createForm?.current?.requestSubmit()}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div >
          </div >
        </div >
      </div >
    </div >
  );
};

export default EditFAQModal;
