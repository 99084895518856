import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../interceptors/axiosConfig";
import { showNotification } from "../../common/components/Toaster/Toast";

export interface faqInterface {
    isLoading: boolean;
    errors: any;
    faqList: any;
}

const initialState: faqInterface = {
    isLoading: false,
    errors: null,
    faqList: "",
}

const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        setErrors: (state, { payload }: PayloadAction<any>) => {
            state.errors = payload;
        },
        setLoading: (state, { payload }: PayloadAction<any>) => {
            state.isLoading = payload;
        },
        setFAQList: (state, { payload }: PayloadAction<any>) => {
            state.faqList = payload;
        },
    }
})

export const faqStore = (body: any, showModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/faq/store";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setLoading(false));
                dispatch(getfaqList());
                showModal(false);
                showNotification({
                    message: "New Question added successfully",
                    theme: "light",
                    type: "success"
                })
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};


export const getfaqList = (): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/faq/index";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setFAQList(response.data?.faq));
                dispatch(setLoading(false));
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};

export const updateFaqDetails = (body: any, showModal: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/faq/update";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setLoading(false));
                showModal(false);
                dispatch(getfaqList());
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};

export const deleteFAQ = (body: any): AppThunk => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        let URL = "/faq/delete";
        const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
        if (response.data) {
            if (response.data.status && response.data.status === "success") {
                dispatch(setLoading(false));
                dispatch(getfaqList());
            }
        }
    } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setErrors(error?.response));
    }
};


export const {
    setLoading,
    setErrors,
    setFAQList,
} = faqSlice.actions

export default faqSlice.reducer;
