import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../redux/hooks";

import AddCareerModal from "./components/AddCareer";
import DeleteModal from "../../../common/components/Modal/DeleteModal/DeleteModal";
import EditCareerModal from "./components/EditCareerModal";

import "./career.scss";
import { ReactComponent as EditIcon } from "../../../assets/icons/icon-edit.svg"
import { ReactComponent as AddCirclePlusIcon } from "../../../assets/icons/icon-add-circle.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/icon-trash.svg";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/icons/icon-dots-vertical.svg";
import { deleteCareer, getCareerList, setCareerList } from "../../../redux/pages/careerSlice";

const Careers = () => {

  const [showCreateModal, setShowCreateModal] = useState<any>(false);
  const [showEditModal, setShowEditModal] = useState<any>(false)
  const [careerData, setCareerData] = useState<any>([]);
  const [selectedFAQ, setSelectedFAQ] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //redux
  const dispatch = useDispatch<any>();
  const careerList = useAppSelector((state) => state.career.careerList);

  useEffect(() => {
    dispatch(getCareerList());
  }, []);

  useEffect(() => {
    if (careerList && careerList.length) {
      setCareerData(careerList);
      dispatch(setCareerList(""));
    }
  }, [careerList]);

  const handleClick = (id: any) => {
    setSelectedFAQ(id);
    setShowDeleteModal(true);
  };

  const onEditClick = (data: any) => {
    if (data) {
      setSelectedFAQ(data);
      setShowEditModal(true);
    }
  };

  const onCancelDeleteAction = () => {
    setSelectedFAQ(null);
  };

  const onDeletequestion = useCallback(() => {
    if (selectedFAQ && selectedFAQ?.id) {
      const body = { id: selectedFAQ?.id };
      dispatch(deleteCareer(body));
    }
  }, [selectedFAQ]);
  
  return (
    <>
      <div className="users-wrapper">
        {/* title section */}
        <div className="d-flex justify-content-between flex-wrap title-wrapper mb-3 mx-4">
          <div>
            <h2 className="title">Manage Careers</h2>
            <p className="desc mb-2">
              Here you can add new Career, manage them etc.
            </p>
          </div>
        </div>
        <div className="card-section mx-4">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-title-wrapper">
                  <div>
                    <h3 className="card-title">Careers</h3>
                    <h5 className="card-subtitle">
                      Manage Careers
                    </h5>
                  </div>
                  <div className="d-flex al gap-3">
                    <button onClick={() => setShowCreateModal(true)} className="btn btn-sm btn-primary">
                      <span className="me-1">
                        <AddCirclePlusIcon />
                      </span>
                      Add Career
                    </button>
                  </div>
                </div>
                <div className="card-inner-scroll-area">
                  <ul className="users-list">
                    {careerData?.map((item: any, index: any) => (
                      <li key={index}>
                        <div className="user-card mb-2 ">
                          <div className="d-flex  align-items-center">
                            <p className="title">{item.title}</p>
                          </div>

                          <div className="d-flex align-items-center gap-3">
                            <div className="dropdown more-dropdown">
                              <button
                                className="dropdown-toggle more-dropdown-btn"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <DotsVerticalIcon />
                              </button>

                              <ul className="dropdown-menu more-dropdown-menu">
                                <li>
                                  <button className="dropdown-item" onClick={() => onEditClick(item)}>
                                    <span className="me-2">
                                      <EditIcon />
                                    </span>
                                    Edit
                                  </button>
                                </li>
                                <li>
                                  <button onClick={() => handleClick(item)} className="dropdown-item text-danger">
                                    <span className="me-2">
                                      <TrashIcon />
                                    </span>
                                    Delete
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal sections */}
      {showCreateModal && <AddCareerModal setShowFAQModal={setShowCreateModal} />}
      {showEditModal && <EditCareerModal data={selectedFAQ} setShowFAQModal={setShowEditModal} />}
      {showDeleteModal && (
        <DeleteModal
          showModal={setShowDeleteModal}
          onConfirm={onDeletequestion}
          onCancel={onCancelDeleteAction}
          message={'Are you sure you want to delete the question?'}
        />
      )}
    </>
  );
};

export default Careers;
