import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./modal-styles.scss";

import { createUser, getRoleList } from "../../../../redux/pages/userSlice";
import { useDispatch } from "react-redux";
import { careerSchema } from "../../../../validations/careerValidation";
import { careerStore } from "../../../../redux/pages/careerSlice";

interface ICareer {
  setShowFAQModal: (value: boolean) => void;
}

const AddCareerModal: React.FC<ICareer> = (props) => {
  const {
    setShowFAQModal = () => { },
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(careerSchema),
  });

  const createForm = useRef<any>();

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getRoleList());
  }, []);

  const onSubmit = (data: any) => {
    if (data) {
      dispatch(careerStore(data, setShowFAQModal));
    }
  }


  return (
    <div
      className="as-modal add-member-modal"
      id="add-member-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog-wrapper"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Career</h4>
              <button
                className="close-modal-btn"
              >
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex gap-4">
                <div className="tab-section">
                  <form onSubmit={handleSubmit(onSubmit)} ref={createForm}>

                    <div className="form-wrapper">


                      <div className="form-group">
                        <h5 className="form-group-label">Title</h5>
                        <input
                          type="text"
                          className={`form-group-control ${errors.title ? "error-border" : ""}`}
                          placeholder="Title"
                          {...register("title")}
                          name="title"
                        />
                        <p className="validation-text">{errors.title?.message}</p>
                      </div>

                      <div className="form-group">
                        <h5 className="form-group-label">Location</h5>
                        <input
                          type="text"
                          className={`form-group-control ${errors.location ? "error-border" : ""}`}
                          placeholder="Location"
                          {...register("location")}
                          name="location"
                        />
                        <p className="validation-text">{errors.location?.message}</p>
                      </div>

                    </div>


                    <div className="d-flex btn-wrapper justify-content-between mt-3">
                      <button
                        onClick={() => setShowFAQModal(false)}
                        type="button"
                        className="btn btn-sm btn-outline-danger"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary close-modal-btn"
                        onClick={() => createForm?.current?.requestSubmit()}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div >
          </div >
        </div >
      </div >
    </div >
  );
};

export default AddCareerModal;
